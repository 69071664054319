import HTMLParser from 'html-react-parser';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';

import { FILE_TYPES } from '@components/fileManager/constants';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import { UploadedFileBox } from '@pages/ApplicationForm/containers/ApplicationFormMain/components/FileFieldApplicationForm/styles';
import UploadSignatureCanvas from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureCanvas';
import UploadSignatureFile from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureFile';
import UploadSignatureInput from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/components/UploadSignatureInput';
import useSignatureFieldApplicationForm, {
	SIGNATURE_FIELD_TYPE_ENUM,
} from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SignatureFieldApplicationForm/hooks';

function SignatureFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const { loading, handleSaveAnswer, handleDeleteAnswer, signatureType, setSignatureType } =
		useSignatureFieldApplicationForm({
			applicationId,
			questionId: question.id,
		});

	const disabled =
		loading ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box mb={5}>
			<Grid container alignItems={'center'}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton
					aria-label="delete answer"
					onClick={handleDeleteAnswer}
					disabled={
						!question.value ||
						loading ||
						Boolean(question.locked_for_applicants) ||
						(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered)
					}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			{question.value ? (
				<>
					<Typography variant={'body2'} color="text" my={2}>
						Signature Uploaded
					</Typography>

					<UploadedFileBox>{question.value}</UploadedFileBox>
				</>
			) : (
				<RadioGroup
					row
					aria-labelledby={`question-${question.id}-radio-group-label`}
					name={`question-${question.id}-radio-group`}
				>
					<FormControlLabel
						control={<Radio />}
						label={'Type'}
						value={SIGNATURE_FIELD_TYPE_ENUM.TYPE}
						checked={signatureType === SIGNATURE_FIELD_TYPE_ENUM.TYPE}
						onChange={() => setSignatureType(SIGNATURE_FIELD_TYPE_ENUM.TYPE)}
						disabled={disabled}
					/>

					<FormControlLabel
						control={<Radio />}
						label={'Draw'}
						value={SIGNATURE_FIELD_TYPE_ENUM.DRAW}
						checked={signatureType === SIGNATURE_FIELD_TYPE_ENUM.DRAW}
						onChange={() => setSignatureType(SIGNATURE_FIELD_TYPE_ENUM.DRAW)}
						disabled={disabled}
					/>

					<FormControlLabel
						control={<Radio />}
						label={'Upload'}
						value={SIGNATURE_FIELD_TYPE_ENUM.UPLOAD}
						checked={signatureType === SIGNATURE_FIELD_TYPE_ENUM.UPLOAD}
						onChange={() => setSignatureType(SIGNATURE_FIELD_TYPE_ENUM.UPLOAD)}
						disabled={disabled}
					/>
				</RadioGroup>
			)}

			<FormControl disabled={disabled} sx={{ width: '100%' }} size={'small'}>
				{signatureType === SIGNATURE_FIELD_TYPE_ENUM.TYPE && !question.value ? (
					<UploadSignatureInput questionId={question.id} disabled={disabled} handleSaveAnswer={handleSaveAnswer} />
				) : null}

				{signatureType === SIGNATURE_FIELD_TYPE_ENUM.DRAW && !question.value ? (
					<UploadSignatureCanvas disabled={disabled} handleSaveAnswer={handleSaveAnswer} />
				) : null}

				{signatureType === SIGNATURE_FIELD_TYPE_ENUM.UPLOAD && !question.value ? (
					<UploadSignatureFile
						disabled={disabled}
						loading={loading}
						fileType={question.rule_set as FILE_TYPES}
						handleSaveAnswer={handleSaveAnswer}
					/>
				) : null}

				<FormHelperText error={question.errors && question.errors.length > 0}>
					{question.errors.join('. ')}
				</FormHelperText>
			</FormControl>
		</Box>
	);
}

export default SignatureFieldApplicationForm;
