import HTMLParser from 'html-react-parser';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import useDateFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/DateFieldApplicationForm/hooks';

function DateFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const {
		loading,
		handleSaveAnswer,
		handleDeleteAnswer,
		yearValue,
		monthValue,
		dayValue,
		handleChangeYear,
		handleChangeMonth,
		handleChangeDay,
		yearOptions,
		monthOptions,
		dayOptions,
	} = useDateFieldApplicationForm({
		applicationId,
		question,
	});

	const disabled =
		loading ||
		question.is_locked ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box mb={5}>
			<Grid container alignItems={'center'} sx={{ mb: 1, flexWrap: 'nowrap' }}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton aria-label="delete answer" onClick={handleDeleteAnswer} disabled={!question.value || disabled}>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			<Grid container spacing={2}>
				<Grid item xs={4}>
					<FormControl fullWidth size="small" disabled={disabled} error={question.errors && question.errors.length > 0}>
						<Select
							labelId={`question-${question.id}-date-year-label`}
							id={`question-${question.id}-date-year`}
							value={yearValue}
							fullWidth
							disabled={!yearOptions.length || Boolean(question.is_encrypted && question.value) || disabled}
							sx={{
								'& .MuiSelect-select .notranslate::after': !yearValue
									? {
											content: question.is_encrypted && question.value ? '"****"' : '"Year"',
											opacity: 0.42,
									  }
									: {},
							}}
							MenuProps={{
								PaperProps: {
									sx: { maxHeight: 300, width: 'auto' },
								},
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left',
								},
							}}
							onChange={e => handleChangeYear(e.target.value as number)}
						>
							{yearOptions.map(option => (
								<MenuItem key={option.id} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>

						{loading ? (
							<Box sx={{ position: 'absolute', top: 10, right: 30 }}>
								<CircularProgress size={20} />
							</Box>
						) : null}

						<FormHelperText error={question.errors && question.errors.length > 0}>
							{question.errors.join('. ')}
						</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={4}>
					<FormControl fullWidth size="small" disabled={disabled} error={question.errors && question.errors.length > 0}>
						<Select
							labelId={`question-${question.id}-date-month-label`}
							id={`question-${question.id}-date-month`}
							value={monthValue}
							fullWidth
							disabled={!monthOptions.length || Boolean(question.is_encrypted && question.value) || disabled}
							sx={{
								'& .MuiSelect-select .notranslate::after': !monthValue
									? {
											content: question.is_encrypted && question.value ? '"**"' : '"Month"',
											opacity: 0.42,
									  }
									: {},
							}}
							MenuProps={{
								PaperProps: {
									sx: { maxHeight: 300, width: 'auto' },
								},
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left',
								},
							}}
							onChange={e => handleChangeMonth(e.target.value as number)}
						>
							{monthOptions.map(option => (
								<MenuItem key={option.id} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>

						{loading ? (
							<Box sx={{ position: 'absolute', top: 10, right: 30 }}>
								<CircularProgress size={20} />
							</Box>
						) : null}
					</FormControl>
				</Grid>

				<Grid item xs={4}>
					<FormControl fullWidth size="small" disabled={disabled} error={question.errors && question.errors.length > 0}>
						<Select
							labelId={`question-${question.id}-date-day-label`}
							id={`question-${question.id}-date-day`}
							value={dayValue}
							fullWidth
							disabled={!dayOptions.length || Boolean(question.is_encrypted && question.value) || disabled}
							sx={{
								'& .MuiSelect-select .notranslate::after': !dayValue
									? {
											content: question.is_encrypted && question.value ? '"**"' : '"Day"',
											opacity: 0.42,
									  }
									: {},
							}}
							MenuProps={{
								PaperProps: {
									sx: { maxHeight: 300, width: 'auto' },
								},
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left',
								},
							}}
							onChange={e => {
								handleChangeDay(e.target.value as number);
								handleSaveAnswer(yearValue as number, monthValue as number, e.target.value as number);
							}}
						>
							{dayOptions.map(option => (
								<MenuItem key={option.id} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>

						{loading ? (
							<Box sx={{ position: 'absolute', top: 10, right: 30 }}>
								<CircularProgress size={20} />
							</Box>
						) : null}
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DateFieldApplicationForm;
