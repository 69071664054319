import HTMLParser from 'html-react-parser';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import useSelectFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/SelectFieldApplicationForm/hooks';

function SelectFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const { loading, handleSaveAnswer, handleDeleteAnswer } = useSelectFieldApplicationForm({
		applicationId,
		questionId: question.id,
	});

	const options = question.options.map(option => ({
		value: option.label,
		id: option.id,
		is_checked: option.is_checked,
		is_visible: option.is_visible,
		system_identifier: option.system_identifier,
	}));

	const disabled =
		loading ||
		question.is_locked ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box mb={5}>
			<Grid container alignItems={'center'} sx={{ mb: 1, flexWrap: 'nowrap' }}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton
					aria-label="delete answer"
					onClick={handleDeleteAnswer}
					disabled={!options.some(({ is_checked }) => is_checked) || disabled}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			<FormControl
				sx={{ width: { xs: '100%', sm: '80%', md: '50%', lg: '33%' } }}
				size="small"
				disabled={disabled}
				error={question.errors && question.errors.length > 0}
			>
				<Select
					labelId={`question-${question.id}-select-label`}
					id={`question-${question.id}-select`}
					value={options.find(option => option.is_checked)?.id || ''}
					sx={{
						'& .MuiSelect-select .notranslate::after': !options.some(({ is_checked }) => is_checked)
							? {
									content: `"${question.label}"`,
									opacity: 0.42,
							  }
							: {},
					}}
					onChange={e => handleSaveAnswer(e.target.value as number)}
				>
					{options
						.filter(({ is_visible }) => is_visible)
						.map(option => (
							<MenuItem key={option.id} value={option.id}>
								{option.value}
							</MenuItem>
						))}
				</Select>

				<FormHelperText error={question.errors && question.errors.length > 0}>
					{question.errors.join('. ')}
				</FormHelperText>

				{loading ? (
					<Box sx={{ position: 'absolute', top: 10, right: 30 }}>
						<CircularProgress size={20} />
					</Box>
				) : null}
			</FormControl>
		</Box>
	);
}

export default SelectFieldApplicationForm;
