import HTMLParser from 'html-react-parser';
import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import useTextFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/TextFieldApplicationForm/hooks';

function TextFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const { loading, handleSaveAnswer, handleDeleteAnswer, textFieldRef } = useTextFieldApplicationForm({
		applicationId,
		questionId: question.id,
		initialValue: question.value as string,
	});

	const [value, updateValue] = useState(question.value);

	const handleUpdate = (evt: any) => {
		const value = evt.target.value;
		switch (question.rule_set) {
			case 'phone': {
				if (/^\+?\d*$/.test(value) || value === '+') {
					updateValue(value);
				}
				break;
			}
			case 'positive-number': {
				if (value === '') {
					updateValue('');
				}
				if (/^[0-9]+(\.[0-9]*)?$/.test(value)) {
					updateValue(value);
				}
				break;
			}
			default:
				updateValue(value);
		}
	};

	const onDelete = () => {
		handleDeleteAnswer(() => {
			updateValue('');
		});
	};

	const disabled =
		loading ||
		question.is_locked ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box mb={3}>
			<Grid container alignItems={'center'} wrap={'nowrap'}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton aria-label="delete answer" onClick={onDelete} disabled={!question.value || disabled}>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			<Grid container alignItems={'flex-start'}>
				<TextField
					inputRef={textFieldRef}
					value={value}
					placeholder={'Answer'}
					size={'small'}
					sx={{ width: { xs: '100%', sm: '80%', md: '50%', lg: '33%' } }}
					disabled={Boolean(question.is_encrypted && question.value) || disabled}
					onBlur={handleSaveAnswer}
					onChange={handleUpdate}
					error={question.errors && question.errors.length > 0}
					helperText={question.errors.join('. ')}
					InputProps={{
						endAdornment: loading ? (
							<InputAdornment position="end">
								<IconButton aria-label="mark field disabled">
									<CircularProgress size={20} />
								</IconButton>
							</InputAdornment>
						) : null,
					}}
				/>
			</Grid>
		</Box>
	);
}

export default TextFieldApplicationForm;
