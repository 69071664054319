import HTMLParser from 'html-react-parser';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import useRadioGroupFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/RadioGroupFieldApplicationForm/hooks';

function RadioGroupFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const { loading, handleSaveAnswer, handleDeleteAnswer } = useRadioGroupFieldApplicationForm({
		applicationId,
		questionId: question.id,
	});

	const options = question.options.map(option => ({
		value: option.label,
		id: option.id,
		is_checked: option.is_checked,
		is_visible: option.is_visible,
		system_identifier: option.system_identifier,
	}));

	const disabled =
		loading ||
		question.is_locked ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box>
			<Grid container alignItems={'center'} sx={{ mb: 1, flexWrap: 'nowrap' }}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton
					aria-label="delete answer"
					onClick={handleDeleteAnswer}
					disabled={!options.some(({ is_checked }) => is_checked) || disabled}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			<FormControl sx={{ mb: 3 }} disabled={disabled} error={question.errors && question.errors.length > 0}>
				<RadioGroup
					row
					aria-labelledby={`question-${question.id}-radio-group-label`}
					name={`question-${question.id}-radio-group`}
					sx={{ display: 'flex', flexDirection: 'column' }}
				>
					{options
						.filter(({ is_visible }) => is_visible)
						.map(option => (
							<FormControlLabel
								key={option.id}
								value={option.value}
								control={<Radio />}
								label={<Typography sx={{ marginTop: '9px' }}>{option.value}</Typography>}
								checked={option.is_checked}
								onChange={() => handleSaveAnswer(option.id)}
								sx={{ display: 'flex', alignItems: 'flex-start' }}
							/>
						))}
				</RadioGroup>

				<FormHelperText error={question.errors && question.errors.length > 0}>
					{question.errors.join('. ')}
				</FormHelperText>
			</FormControl>
		</Box>
	);
}

export default RadioGroupFieldApplicationForm;
