import HTMLParser from 'html-react-parser';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import useStateFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/StateFieldApplicationForm/hooks';

function StateFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const { options, loading, handleSaveAnswer, handleDeleteAnswer } = useStateFieldApplicationForm({
		applicationId,
		questionId: question.id,
	});

	const disabled =
		loading ||
		question.is_locked ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box mb={5}>
			<Grid container alignItems={'center'} sx={{ mb: 1, flexWrap: 'nowrap' }}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton aria-label="delete answer" onClick={handleDeleteAnswer} disabled={!question.value || disabled}>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			<FormControl
				sx={{ width: { xs: '100%', sm: '80%', md: '50%', lg: '33%' } }}
				size="small"
				disabled={disabled}
				error={question.errors && question.errors.length > 0}
			>
				<Select
					labelId={`question-${question.id}-state-label`}
					id={`question-${question.id}-state`}
					placeholder={question.label}
					value={question.value}
					sx={{
						'& .MuiSelect-select .notranslate::after': !question.value
							? {
									content: '"State"',
									opacity: 0.42,
							  }
							: {},
					}}
					onChange={e => handleSaveAnswer(e.target.value as string)}
					MenuProps={{
						PaperProps: {
							sx: { maxHeight: 300, width: 'auto' },
						},
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					{options.map(option => (
						<MenuItem key={option.id} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>

				<FormHelperText error={question.errors && question.errors.length > 0}>
					{question.errors.join('. ')}
				</FormHelperText>

				{loading ? (
					<Box sx={{ position: 'absolute', top: 10, right: 30 }}>
						<CircularProgress size={20} />
					</Box>
				) : null}
			</FormControl>
		</Box>
	);
}

export default StateFieldApplicationForm;
