import HTMLParser from 'html-react-parser';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';

import FileManager from '@components/fileManager';
import { FILE_TYPES } from '@components/fileManager/constants';

import { ApplicationFormSelectedQuestion } from '@pages/ApplicationForm/containers/ApplicationFormMain';
import useFileFieldApplicationForm from '@pages/ApplicationForm/containers/ApplicationFormMain/components/FileFieldApplicationForm/hooks';
import { UploadedFileBox } from '@pages/ApplicationForm/containers/ApplicationFormMain/components/FileFieldApplicationForm/styles';

function FileFieldApplicationForm({
	applicationId,
	question,
}: {
	applicationId: number;
	question: ApplicationFormSelectedQuestion;
}) {
	const { loading, handleSaveAnswer, handleDeleteAnswer } = useFileFieldApplicationForm({
		applicationId,
		questionId: question.id,
	});

	const disabled =
		loading ||
		Boolean(question.locked_for_applicants) ||
		(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered);

	return (
		<Box mb={5}>
			<Grid container alignItems={'center'}>
				<Typography variant={'body2'} fontWeight={500}>
					{question.label}
					{question.is_optional ? '' : ' *'}
				</Typography>

				<IconButton
					aria-label="delete answer"
					onClick={handleDeleteAnswer}
					disabled={
						!question.value ||
						loading ||
						Boolean(question.locked_for_applicants) ||
						(Boolean(question.locked_for_applicants_if_answered_by_non_applicant) && !question.is_self_answered)
					}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>

			{question.description ? (
				<Box mb={2}>
					<Typography variant={'caption'} fontWeight={500}>
						{HTMLParser(question.description)}
					</Typography>
				</Box>
			) : null}

			<FormControl>
				{question.value ? (
					<UploadedFileBox>{question.value}</UploadedFileBox>
				) : (
					<FileManager type={question.rule_set as FILE_TYPES} onChange={handleSaveAnswer}>
						{({ handleOpenFileProvider }) => (
							<Button
								variant="contained"
								color="primary"
								disabled={disabled}
								startIcon={loading ? <CircularProgress color={'info'} size={20} /> : <FileUploadIcon />}
								onClick={handleOpenFileProvider}
								sx={{ width: 140, height: 40, textTransform: 'capitalize' }}
							>
								<b>Upload</b>
							</Button>
						)}
					</FileManager>
				)}

				<FormHelperText error={question.errors && question.errors.length > 0}>
					{question.errors.join('. ')}
				</FormHelperText>
			</FormControl>
		</Box>
	);
}

export default FileFieldApplicationForm;
